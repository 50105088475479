import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface MessageFormData {
    title: string;
    message: string;
}

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    constructor(
        private readonly httpClient: HttpClient
    ) { }

    public sendMessage(formData: MessageFormData): Observable<{ success: boolean }> {
        return this.httpClient.post<{ success: boolean }>('/message/create', formData);
    }
}