import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Logger } from './../../core/logger.service';
import { WsService } from './ws.service';

@Injectable({
    providedIn: 'root'
})
export class ConectionService implements Resolve<void> {
    private readonly logger = new Logger(ConectionService.name);

    private timerInterval = null;

    constructor(
        private readonly wsService: WsService,
    ) { }

    resolve(): void {
        this.startConectionRefreshTimer();
    }

    public clear(): void {
        this.clearTimerInterval();
    }

    private startConectionRefreshTimer(): void {
        this.timerInterval = setInterval(() => {
            this.refreshConection();
        }, 60000);
    }

    private refreshConection(): void {
        this.wsService.sendMessage({ event: 'conection.ping', data: {} });
    }

    private clearTimerInterval(): void {
        clearInterval(this.timerInterval);
    }
}
