import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-transaction-success-modal',
  templateUrl: './transaction-success-modal.component.html',
  styleUrls: ['./transaction-success-modal.component.scss']
})
export class TransactionSuccessModalComponent {

  constructor(
    private readonly activeModal: NgbActiveModal
  ) { }

  public closeModal(): void {
    this.activeModal.close();
  }
}
