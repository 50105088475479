export enum MessageType {
    ROOM_INFO = 'ROOM_INFO',
    ROOM_SELECTED = 'ROOM_SELECTED',
    ROOM_END = 'ROOM_END',
    SEQUENCE = 'SEQUENCE',
    JOIN_GAME = 'JOIN_GAME',
    PLAYER_NEW_ROUND = 'PLAYER_NEW_ROUND',
    NEW_ROUND = 'NEW_ROUND',
    PRIZE_FUND = 'PRIZE_FUND',
    PLAYER_ROOM_END = 'PLAYER_ROOM_END',
}

export interface GoldenlineEventMessage {
    type: MessageType;
    gameId: string;
    message?: any;
}