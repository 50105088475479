import { Injectable } from '@angular/core';
import { AppConfigService } from './../../app-config.service';

export interface Credentials {
  email: string;
  token: string;
}

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {

  private _credentials: Credentials | null = null;
  private clientId: string;
  private readonly credentialsKey = AppConfigService.config.credentialsKey

  constructor() {
    const savedCredentials = sessionStorage.getItem(this.credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  public isAuthenticated(): boolean {
    return !!this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  public get credentials(): Credentials | null {
    return this._credentials;
  }

  public setClientId(clientId: string): void {
    this.clientId = clientId;
  }

  public getClientId(): string {
      return this.clientId;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   */
  public setCredentials(credentials?: Credentials): void {
    this._credentials = credentials || null;

    if (credentials) {
      sessionStorage.setItem(this.credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(this.credentialsKey);
    }
  }
}
