import { TwoFactorAuthModalComponent } from './../../shared/modals/two-factor-auth/two-factor-auth-modal/two-factor-auth-modal.component';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, from, Observable, throwError } from "rxjs";
import { catchError, exhaustMap } from 'rxjs/operators';

@Injectable()
export class TwoFactorAuthInterceptor implements HttpInterceptor {

  constructor(
    private readonly ngbModal: NgbModal
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.error && error.error.error && error.error.error.action == 'enterAuthenticatorCode') {
            return from(this.ngbModal.open(TwoFactorAuthModalComponent, { backdrop: 'static', centered: true, size: 'sm', keyboard: false }).result.then())
              .pipe(
                exhaustMap(result => {
                  if (!result) {
                    return EMPTY;
                  }

                  const duplicate = request.clone({
                    body: {
                      ...request.body,
                      authenticatorCode: result.code
                    }
                  });
                  return next.handle(duplicate);
                })
              );
          }
          else {
            return throwError(error);
          }
        })
      );
  }
}