import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-successful-modal',
  templateUrl: './confirm-successful-modal.component.html',
  styleUrls: ['./confirm-successful-modal.component.scss']
})
export class ConfirmSuccessfulModalComponent {
  
  @Input() public title = 'Success!';
  @Input() public text = 'Action was successful!';

  constructor(
    private readonly activeModal: NgbActiveModal,
  ) { }

  public close(): void {
    this.activeModal.close();
  }
}
