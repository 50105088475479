import { EventEmitter, Injectable } from '@angular/core';
import { CredentialsService } from 'app/core/authentication/credentials.service';
import { WsService } from 'app/shared/services/ws.service';
import { EventEmitter as Listener } from 'events';
import { GoldenlineEventMessage, MessageType } from './types/events/GoldenlineEventMessage';
import { GoldenlineJoinGameEventData } from './types/events/GoldenlineJoinGameEventData';
import { GoldenlineNewRoomEventData } from './types/events/GoldenlineNewRoomEventData';
import { GoldenlineNewRoundEventData } from './types/events/GoldenlineNewRoundEventData';
import { GoldenlinePlayerNewRoundEventData } from './types/events/GoldenlinePlayerNewRoundEventData';
import { GoldenlinePlayerRoomEndEventData } from './types/events/GoldenlinePlayerRoomEndEventData';
import { GoldenlinePrizeFundEventData } from './types/events/GoldenlinePrizeFundEventData';
import { GoldenlineRoomEndEventData } from './types/events/GoldenlineRoomEndEventData';
import { GoldenlineRoomInfoEventData } from './types/events/GoldenlineRoomInfoEventData';
import { GoldenlineRoomSelectedEventData } from './types/events/GoldenlineRoomSelectedEventData';
import { GoldenlineRoomsEventData } from './types/events/GoldenlineRoomsEventData';
import { GoldenlineSequenceEventData } from './types/events/GoldenlineSequenceEventData';
import { GoldenlinePlayerRoom } from './types/GoldenlinePlayerRoom';
import { GoldenlineRoom } from './types/GoldenlineRoom';

@Injectable({
    providedIn: 'root',
})
export class GoldenlineService {

    public readonly glodenlineGameEvent: EventEmitter<GoldenlineEventMessage> = new EventEmitter<GoldenlineEventMessage>();

    private rooms: GoldenlineRoom[] = [];
    private playerRooms: GoldenlinePlayerRoom[] = [];
    private messageListener: Listener = null;
    private timerEnabled = false;
    private timerInterval = null;

    private readonly eventHandler: { [event: string]: { emit: (data: any) => void } } = {
        'goldenline.new-game': { emit: this.onNewRoomEvent.bind(this) },
        'goldenline.games': { emit: this.onRoomsEvent.bind(this) },
        'goldenline.room': { emit: this.onRoomInfoEvent.bind(this) },
        'goldenline.sequence': { emit: this.onSequenceEvent.bind(this) },
        'goldenline.room-selected': { emit: this.onRoomSelectedEvent.bind(this) },
        'goldenline.new-round': { emit: this.onNewRoundEvent.bind(this) },
        'goldenline.player-new-round': { emit: this.onPlayerNewRoundEvent.bind(this) },
        'goldenline.prize-fund': { emit: this.onPrizeFundEvent.bind(this) },
        'goldenline.joingame': { emit: this.onJoinGameEvent.bind(this) },
        'goldenline.game-end': { emit: this.onRoomEndEvent.bind(this) },
        'goldenline.player-game-end': { emit: this.onPlayerRoomEndEvent.bind(this) },
    };

    constructor(
        private readonly credentialsService: CredentialsService,
        private readonly wsService: WsService
    ) {
        this.clearState();
    }

    public connect(): void {
        this.wsService.sendMessage({ event: 'goldenline.getrooms', data: { playerId: this.credentialsService.getClientId() } });

        this.messageListener = this.wsService.getMessages().addListener('goldenline', (message: { data: any, event: string }) => {
            if (!message || !message.event) {
                return;
            }

            const event = this.eventHandler[message.event];
            if (!event) {
                return;
            }

            event.emit(message.data);
        });
    }

    public disconnect(): void {
        if (this.messageListener) {
            this.messageListener.removeAllListeners();
        }

        this.clearState();
    }

    public clearState(): void {
        this.rooms = [];
        this.playerRooms = [];
        this.timerEnabled = false;
        this.clearRoomTimerInterval();
    }

    public getRooms(): GoldenlineRoom[] {
        return this.rooms;
    }

    public getPlayerRooms(): GoldenlinePlayerRoom[] {
        return this.playerRooms;
    }

    public getRoomById(gameId: string): null | GoldenlineRoom {
        if (this.rooms.length === 0) {
            return null;
        }

        return this.rooms.find(r => r.gameId === gameId);
    }

    public selectRoom(roomGameId: string): void {
        this.wsService.sendMessage({ event: 'goldenline.selectroom', data: { playerId: this.credentialsService.getClientId(), gameId: roomGameId } });
    }

    public joinGame(roomGameId: string, sequence: string) {
        this.wsService.sendMessage({ event: 'goldenline.joingame', data: { playerId: this.credentialsService.getClientId(), gameId: roomGameId, sequence: sequence } });
    }

    public submitVote(roomGameId: string, vote: { [votedNumber: number]: number }) {
        this.wsService.sendMessage({ event: 'goldenline.vote', data: { playerId: this.credentialsService.getClientId(), gameId: roomGameId, voteSequence: vote } });
    }

    public generateSequence(roomGameId: string) {
        this.wsService.sendMessage({ event: 'goldenline.generates', data: { playerId: this.credentialsService.getClientId(), gameId: roomGameId } });
    }

    public getRoomInfo(roomGameId: string) {
        this.wsService.sendMessage({ event: 'goldenline.roomInfo', data: { gameId: roomGameId } });
    }

    public changeCurrentActivePlayerRoom(roomId: string): void {
        this.removeCurrentActivePlayerRoom();

        const newActiveRoom = this.playerRooms.find(r => r.gameId === roomId);
        if (!newActiveRoom) {
            return;
        }

        newActiveRoom.currentActive = true;
    }

    public removeCurrentActivePlayerRoom(): void {
        const currentActive = this.playerRooms.find(r => r.currentActive === true);
        if (!currentActive) {
            return;
        }

        currentActive.currentActive = false;
    }

    private onNewRoomEvent(data: GoldenlineNewRoomEventData): void {
        this.addRooms(data);
    }

    private onRoomsEvent(data: GoldenlineRoomsEventData): void {
        this.addRooms(data.games);
        this.addPlayerRooms(data.playerGames);
    }

    private onRoomInfoEvent(data: GoldenlineRoomInfoEventData): void {
        this.glodenlineGameEvent.emit({ type: MessageType.ROOM_INFO, message: data, gameId: data.gameId });
    }

    private onSequenceEvent(data: GoldenlineSequenceEventData): void {
        this.glodenlineGameEvent.emit({ type: MessageType.SEQUENCE, message: data, gameId: data.gameId });
    }

    private onRoomSelectedEvent(data: GoldenlineRoomSelectedEventData): void {
        this.glodenlineGameEvent.emit({ type: MessageType.ROOM_SELECTED, message: data, gameId: data.gameState.gameId });
    }

    private onJoinGameEvent(data: GoldenlineJoinGameEventData): void {
        this.addPlayerRoom(data);
        this.glodenlineGameEvent.emit({ type: MessageType.JOIN_GAME, message: data, gameId: data.gameId });
    }

    private onPrizeFundEvent(data: GoldenlinePrizeFundEventData): void {
        this.glodenlineGameEvent.emit({ type: MessageType.PRIZE_FUND, message: data, gameId: data.gameId });
        this.updateRoomPrizeFund(data);
    }

    private onNewRoundEvent(data: GoldenlineNewRoundEventData): void {
        this.glodenlineGameEvent.emit({ type: MessageType.NEW_ROUND, message: data, gameId: data.gameId });
    }

    private onPlayerNewRoundEvent(data: GoldenlinePlayerNewRoundEventData): void {
        this.glodenlineGameEvent.emit({ type: MessageType.PLAYER_NEW_ROUND, message: data, gameId: data.playerState.gameId });
    }

    private onRoomEndEvent(data: GoldenlineRoomEndEventData): void {
        this.glodenlineGameEvent.emit({ type: MessageType.ROOM_END, message: data, gameId: data.gameId });
        this.removeRoom(data);
        this.removePlayerRoom(data);
    }

    private onPlayerRoomEndEvent(data: GoldenlinePlayerRoomEndEventData): void {
        this.glodenlineGameEvent.emit({ type: MessageType.PLAYER_ROOM_END, message: data, gameId: data.gameId });
    }

    private addRooms(rooms: GoldenlineRoom[]): void {
        if (!Array.isArray(rooms)) {
            return;
        }

        for (let room of rooms) {
            const existingRoom = this.rooms.find(r => r.gameId === room.gameId);
            if (existingRoom) {
                existingRoom.remainingSeconds = room.remainingSeconds;
                existingRoom.remainingRoundSeconds = room.remainingRoundSeconds;

                continue;
            }

            this.rooms.push({
                currency: room.currency,
                entryFee: room.entryFee,
                prizeFund: room.prizeFund,
                totalDurationSeconds: room.totalDurationSeconds,
                remainingSeconds: room.remainingSeconds,
                remainingRoundSeconds: room.remainingRoundSeconds,
                gameId: room.gameId,
                label: room.label
            });
        }

        if (this.timerEnabled === false && this.rooms.length > 0) {
            this.startRoomTimer();
        }
    }

    private addPlayerRooms(rooms: GoldenlinePlayerRoom[]): void {
        if (!Array.isArray(rooms)) {
            return;
        }

        for (let room of rooms) {
            const existingRoom = this.playerRooms.find(r => r.gameId === room.gameId);
            if (existingRoom) {
                continue;
            }

            this.addPlayerRoom(room);
        }
    }

    private addPlayerRoom(room: GoldenlinePlayerRoom | GoldenlineJoinGameEventData): void {
        this.playerRooms.push({
            gameId: room.gameId,
            currency: room.currency,
            entryFee: room.entryFee
        });
    }

    private updateRoomPrizeFund(room: { gameId: string, prizeFund: number }): void {
        const existingRoom = this.rooms.find(r => r.gameId === room.gameId);
        if (!existingRoom) {
            return;
        }

        existingRoom.prizeFund = room.prizeFund;
    }

    private removeRoom(room: GoldenlineRoomEndEventData): void {
        const index = this.rooms.findIndex(r => r.gameId === room.gameId);
        if (index < 0) {
            return;
        }

        this.rooms.splice(index, 1);
    }

    private removePlayerRoom(room: GoldenlineRoomEndEventData): void {
        if (this.playerRooms.length === 0) {
            return;
        }

        const index = this.playerRooms.findIndex(r => r.gameId === room.gameId);
        if (index < 0) {
            return;
        }

        this.playerRooms.splice(index, 1);
    }

    private startRoomTimer(): void {
        this.timerEnabled = true;

        this.timerInterval = setInterval(() => {
            if (this.rooms.length === 0) {
                this.timerEnabled = false;
                this.clearRoomTimerInterval();
            }

            for (const room of this.rooms) {
                room.remainingSeconds -= 1;
            }
        }, 1000);
    }

    private clearRoomTimerInterval(): void {
        clearInterval(this.timerInterval);
    }
}
