import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from './../../app-config.service';

/**
 * Prefixes all requests not starting with `http[s]` with authorization token.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiTokenInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = '';

    const savedCredentials = sessionStorage.getItem(AppConfigService.config.credentialsKey);
    if (savedCredentials) {
      token = JSON.parse(savedCredentials).token;
    }

    const clonedRequest = request.clone({ headers: request.headers.set('Authorization', token) });
    return next.handle(clonedRequest);
  }
}
