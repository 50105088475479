import { Pipe, PipeTransform } from '@angular/core';
import { AccountService } from './../services/account.service';
import { Account } from './../types/Account';

@Pipe({ name: 'accounts', pure: false })
export class AccountsPipe implements PipeTransform {

  constructor(
    private readonly accountService: AccountService
  ) { }

  transform(): Account[] {
    return this.accountService.getAll();
  }
}
