import { WithdrawService, WithdrawFormData } from './../../services/withdraw.service';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from './../../services/account.service';
import { ModalService } from './../../services/modal.service';
import { Account } from './../../types/Account';
import { ConfirmTransactionModalComponent } from './../confirm-transaction-modal/confirm-transaction-modal.component';

@Component({
  selector: 'app-withdraw-modal',
  templateUrl: './withdraw-modal.component.html',
  styleUrls: ['./withdraw-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WithdrawModalComponent {

  public tabIndex = 0;
  public accounts: Account[] = [];
  public selectedAccount: Account = null;
  public formSubmited = false;

  public readonly withdrawForm = new FormGroup({
    address: new FormControl(null, [Validators.required]),
    amount: new FormControl(null, [Validators.required]),
    note: new FormControl(null),
  });

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly ngbModal: NgbModal,
    private readonly accountService: AccountService,
    private readonly modalService: ModalService,
    private readonly withdrawService: WithdrawService,
  ) { }

  ngOnInit(): void {
    this.accounts = this.accountService.getAll();
    if (this.accounts.length !== 0) {
      this.selectedAccount = this.accounts[0];

      if (!this.selectedAccount.address) {
        this.generateAccountAddress();
      }

      this.resetFields();
    }
  }

  public closeModal() {
    this.activeModal.close();
  }

  public changeTab(tabIndex: number): void {
    if (!this.accounts[tabIndex]) {
      return;
    }

    this.selectedAccount = this.accounts[tabIndex];
    this.tabIndex = tabIndex;

    if (!this.selectedAccount.address) {
      this.generateAccountAddress();
    }

    this.resetFields();
  }

  private resetFields(): void {
    this.withdrawForm.get('address').setValue(null);
    this.withdrawForm.get('amount').setValue(null);
    this.withdrawForm.get('note').setValue(null);
    this.withdrawForm.reset();

    this.withdrawForm.get('amount').setValidators([
      Validators.required,
      Validators.min(Number(this.selectedAccount.minWithdrawAmount)),
      Validators.max(Number(this.selectedAccount.balance)),
    ]);

    this.withdrawForm.updateValueAndValidity();
  }

  public submit(): void {
    if (this.withdrawForm.invalid || this.formSubmited) {
      return;
    }

    this.formSubmited = true;

    const formData: WithdrawFormData = {
      accountId: this.selectedAccount.id,
      address: this.withdrawForm.get('address').value,
      amount: Number(this.withdrawForm.get('amount').value),
      note: this.withdrawForm.get('note').value,
    };

    this.withdrawService.createWithdrawTransaction(formData).subscribe(transaction => {
      const confirmModalRef = this.ngbModal.open(ConfirmTransactionModalComponent, { backdrop: 'static', centered: true, size: 'lg', windowClass: 'modal-custom-confirm' });
      confirmModalRef.componentInstance.transaction = transaction;
      this.closeModal();
    }, error => {
      this.formSubmited = false;
      this.modalService.showErrorModal(error?.error);
    });
  }

  private generateAccountAddress(): void {
    this.accountService.generateAccountAddress({ accountId: this.selectedAccount.id }).subscribe(res => {
    }, error => {
      this.modalService.showErrorModal(error?.error);
    })
  }
}
