import { Decimal as DecimalJS } from 'decimal.js';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from './../../services/account.service';
import { ExchangeFormData, ExchangeService } from './../../services/exchange.service';
import { ModalService } from './../../services/modal.service';
import { Account } from './../../types/Account';
import { ExchangePair } from './../../types/ExchangePair';
import { ConfirmTransactionModalComponent } from './../confirm-transaction-modal/confirm-transaction-modal.component';

@Component({
  selector: 'app-deposit-modal',
  templateUrl: './deposit-modal.component.html',
  styleUrls: ['./deposit-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DepositModalComponent {

  public tabIndex = 0;
  public accounts: Account[] = [];
  public depsoitAccount: Account = null;

  public exchangePair: ExchangePair = null;
  public exchangeAccountFromBalance = 0;
  public exchangeAccountToBalance = 0;
  public exchangeFormSubmited = false;
  public readonly exchangeForm = new FormGroup({
    amountFrom: new FormControl(null, [Validators.required]),
    amountTo: new FormControl(null),
  });

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly ngbModal: NgbModal,
    private readonly accountService: AccountService,
    private readonly exchangeService: ExchangeService,
    private readonly modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.accounts = this.accountService.getAll();
    if (this.accounts.length !== 0) {
      this.depsoitAccount = this.accounts[0];

      if (!this.depsoitAccount.address) {
        this.generateAccountAddress();
      }
    }

    this.setExchangeTab();
  }

  public closeModal() {
    this.activeModal.close();
  }

  public changeTab(tabIndex: number): void {
    if (this.accounts[tabIndex]) {
      this.depsoitAccount = this.accounts[tabIndex];

      if (!this.depsoitAccount.address) {
        this.generateAccountAddress();
      }

      this.clearExchangeTabFields();
    } else {
      this.depsoitAccount = null;
    }

    this.tabIndex = tabIndex;
  }

  public exchangeAmountFromInputEvent(): void {
    const amountFrom = this.exchangeForm.get('amountFrom').value;
    if (!amountFrom) {
      this.exchangeForm.get('amountTo').setValue(null);
      return;
    }

    const amountTo = new DecimalJS(amountFrom).mul(this.exchangePair.rate).toNumber();
    this.exchangeForm.get('amountTo').setValue(amountTo);
  }

  public exchangeAmountToInputEvent(): void {
    const amountTo = this.exchangeForm.get('amountTo').value;
    if (!amountTo) {
      this.exchangeForm.get('amountFrom').setValue(null);
      return
    }

    const amountFrom = new DecimalJS(amountTo).div(this.exchangePair.rate).toNumber();
    const amountFromControl = this.exchangeForm.get('amountFrom');
    amountFromControl.setValue(amountFrom);

    if (amountFromControl.touched === false) {
      amountFromControl.markAsTouched();
    }
  }

  public makeExchange(): void {
    if (this.exchangeForm.invalid || this.exchangeFormSubmited) {
      return;
    }

    this.exchangeFormSubmited = true;

    const formData: ExchangeFormData = {
      exchangePairId: this.exchangePair.id,
      amount: Number(this.exchangeForm.get('amountFrom').value),
    }

    this.exchangeService.createExchangeTransaction(formData).subscribe(transaction => {
      const confirmModalRef = this.ngbModal.open(ConfirmTransactionModalComponent, { backdrop: 'static', centered: true, size: 'lg', windowClass: 'modal-custom-confirm' });
      confirmModalRef.componentInstance.transaction = transaction;

      this.closeModal();
    }, error => {
      this.exchangeFormSubmited = false;
      this.modalService.showErrorModal(error?.error);
    });
  }

  private clearExchangeTabFields(): void {
    this.exchangeForm.get('amountFrom').setValue(null);
    this.exchangeForm.get('amountTo').setValue(null);

    this.exchangeForm.reset();
    this.exchangeForm.updateValueAndValidity();
  }

  private setExchangeTab(): void {
    const exchangeAccountFrom = this.accounts.find(a => a.currencyCode === 'USDT');
    const exchangeAccountTo = this.accounts.find(a => a.currencyCode === 'VOC');
    this.exchangeAccountFromBalance = Number(exchangeAccountFrom.balance);
    this.exchangeAccountToBalance = Number(exchangeAccountTo.balance);

    this.exchangeForm.get('amountFrom').setValidators([Validators.required, Validators.max(this.exchangeAccountFromBalance)]);
    this.exchangeForm.updateValueAndValidity();

    this.setExchangePairs();
  }

  private setExchangePairs(): void {
    this.exchangeService.getExchangePairs().subscribe(exchangePairs => {
      this.exchangePair = exchangePairs.find(p => p.currencyCodeFrom === 'USDT' && p.currencyCodeTo === 'VOC');
    }, error => {
      this.modalService.showErrorModal(error?.error);
    })
  }

  private generateAccountAddress(): void {
    this.accountService.generateAccountAddress({ accountId: this.depsoitAccount.id }).subscribe(res => {
    }, error => {
      this.modalService.showErrorModal(error?.error);
    });
  }
}
