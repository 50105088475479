import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ExchangeService } from './../../services/exchange.service';
import { ModalService } from './../../services/modal.service';
import { WithdrawService } from './../../services/withdraw.service';
import { Transaction } from './../../types/Transaction';
import { TermsConditionsModalComponent } from './../terms-conditions-modal/terms-conditions-modal.component';
import { TransactionSuccessModalComponent } from './../transaction-success-modal/transaction-success-modal.component';

@Component({
  selector: 'app-confirm-transaction-modal',
  templateUrl: './confirm-transaction-modal.component.html',
  styleUrls: ['./confirm-transaction-modal.component.scss']
})
export class ConfirmTransactionModalComponent {
  @Input() public transaction: Partial<Transaction>;

  private readonly confirmParams: { [activity: string]: { confirm: (formData: { transactionId: number }) => Observable<{ success: boolean }> } } = {
    'WITHDRAW': { confirm: this.withdrawService.confirmWithdrawTransaction.bind(this.withdrawService) },
    'EXCHANGE': { confirm: this.exchangeService.confirmExchangeTransaction.bind(this.exchangeService) },
  }

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly ngbModal: NgbModal,
    private readonly modalService: ModalService,
    private readonly withdrawService: WithdrawService,
    private readonly exchangeService: ExchangeService,
  ) { }

  public closeModal(): void {
    this.activeModal.close();
  }

  public openTermsAnConditionsdModal(): void {
    this.ngbModal.open(TermsConditionsModalComponent, { backdrop: 'static', centered: true, size: 'lg' });
  }

  public confirm(): void {
    const formData = {
      transactionId: this.transaction.id,
    };

    this.confirmParams[this.transaction.activity].confirm(formData).subscribe(res => {
      this.ngbModal.open(TransactionSuccessModalComponent, { backdrop: 'static', centered: true, size: 'md' });
      this.closeModal();
    }, error => {
      this.modalService.showErrorModal(error?.error);
    });
  }
}
