import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserGameResult } from './../../types/UserGameResult';

@Component({
  selector: 'app-results-modal',
  templateUrl: './results-modal.component.html',
  styleUrls: ['./results-modal.component.scss']
})
export class ResultsModalComponent {

  @Input() public result: UserGameResult;

  constructor(
    private readonly activeModal: NgbActiveModal,
  ) { }

  public closeModal(): void {
    this.activeModal.close();
  }
}
