import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { EventEmitter as Listener } from 'events';
import { Logger } from './../../core/logger.service';
import { Account } from './../types/Account';
import { WsService } from './ws.service';

@Injectable({
    providedIn: 'root'
})
export class AccountService implements Resolve<void> {
    private readonly logger = new Logger(AccountService.name);

    private messageListener: Listener = null;
    private accounts: Account[] = [];

    constructor(
        private readonly wsService: WsService,
        private readonly httpClient: HttpClient,
    ) { }

    resolve(): void {
        this.connect();
    }

    public connect(): void {
        this.messageListener = this.wsService.getMessages().addListener('accounts', (message: { data: Partial<Account> | Account[], event: string }) => {
            if (!message || !message.event) {
                return;
            }

            if (message.event === 'accounts.account-list') {
                const accounts = message.data as Account[];
                this.updateAccounts(accounts);
            } else if (message.event === 'accounts.balance-update') {
                const acccount = message.data as Partial<Account>;
                this.updateAccountBalance(acccount);
            }
        });
    }

    public disconnect(): void {
        if (this.messageListener) {
            this.messageListener.removeAllListeners();
        }

        this.accounts = [];
    }

    public getAll(): Account[] {
        return this.accounts;
    }

    public getOneByCurrency(currencyCode: string): Account {
        return this.accounts.find(a => a.currencyCode === currencyCode);
    }

    public generateAccountAddress(formData: { accountId: number }): Observable<{ address: string }> {
        return this.httpClient.post<{ address: string }>('/account/generate/address', formData);
    }

    private updateAccounts(accounts: Account[]): void {
        for (const account of accounts) {
            const existingAccount = this.accounts.find(a => a.id === account.id);
            if (existingAccount) {
                existingAccount.balance = account.balance;
                existingAccount.address = account.address;

                continue;
            }

            this.accounts.push(account);
        }
    }

    private updateAccountBalance(account: Partial<Account>): void {
        const existingAccount = this.accounts.find(a => a.id === account.id);
        if (!existingAccount) {
            return;
        }

        existingAccount.balance = account.balance;
    }
}
