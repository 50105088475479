import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-unsuccessful-modal',
  templateUrl: './unsuccessful-modal.component.html',
  styleUrls: ['./unsuccessful-modal.component.scss']
})
export class UnsuccessfulModalComponent {

  @Input() public title: string = 'Sorry!';
  @Input() public text: string = 'Service is currently unavailable!';

  constructor(
    public readonly activeModal: NgbActiveModal,
  ) { }
  
}
