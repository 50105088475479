import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthModalComponent } from './../../../shared/modals/auth-modal/auth-modal.component';

@Component({
  selector: 'app-main-layout-footer-menu-component',
  templateUrl: './main-layout-footer-menu.component.html',
  styleUrls: ['./main-layout-footer-menu.component.scss']
})
export class MainLayoutFooterMenuComponent {

  constructor(
    private readonly ngbModal: NgbModal
  ) { }

  public openAuthorizationModal() {
    this.ngbModal.open(AuthModalComponent, { backdrop: 'static', centered: true, size: 'lg' });
  }
}
