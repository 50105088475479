import { NgModule } from '@angular/core';
import { SharedModule } from './../../shared/shared.module';
import { AuthenticatedLayoutComponent } from './authenticated-layout.component';
import { AuthenticatedFooterMenuComponent } from './components/authenticated-footer-menu/authenticated-footer-menu.component';
import { AuthenticatedHeaderComponent } from './components/authenticated-header/authenticated-header.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    AuthenticatedLayoutComponent,
    AuthenticatedHeaderComponent,
    AuthenticatedFooterMenuComponent
  ]
})
export class AuthenticatedModule {
}
