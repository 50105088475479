import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-authenticated-layout',
  templateUrl: './authenticated-layout.component.html',
  styleUrls: ['./authenticated-layout.component.scss']
})
export class AuthenticatedLayoutComponent implements OnInit {

  public path: string = "";
  private _router: Subscription;

  constructor(
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly element: ElementRef,
    public readonly location: Location
  ) { }

  ngOnInit(): void {
    const navbar: HTMLElement = this.element.nativeElement.children[0].children[0];

    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      this.path = this.router.url;

      if (window.outerWidth > 991) {
        window.document.children[0].scrollTop = 0;
      } else {
        window.document.activeElement.scrollTop = 0;
      }
    });

    this.path = this.router.url;

    this.renderer.listen('window', 'scroll', (event) => {
      const number = window.scrollY;

      let _location = this.location.path();
      _location = _location.split('/')[2];

      if (number > 78 || window.pageYOffset > 78) {
        navbar.classList.remove('navbar-transparent');
      } else if (_location !== 'login' && this.location.path() !== '/nucleoicons') {
        // remove logic
        navbar.classList.add('navbar-transparent');
      }
    });
  }
}
