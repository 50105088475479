import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-progress-in-modal',
  templateUrl: './progress-in-modal.component.html',
  styleUrls: ['./progress-in-modal.component.scss']
})
export class ProgressInModalComponent {
  @Input() public type: string;

  constructor(
    private ngbModal: NgbModal,
    public activeModal: NgbActiveModal,
  ) { }
}
