import { PlayerRoom } from './../types/PlayerRoom';
import { Pipe, PipeTransform } from "@angular/core";
import { GameService } from './../services/game.service';

@Pipe({
    name: 'playerRooms',
    pure: false,
})
export class PlayerRoomPipe implements PipeTransform {
    
    constructor(
        private readonly gameService: GameService
    ) { }

    public transform(gameCode: string): PlayerRoom[] {
        if (!gameCode) {
            return [];
        }

        return this.gameService.getPlayerRooms(gameCode);
    }
}