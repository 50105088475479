import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailSentModalComponent } from '../email-sent-modal/email-sent-modal.component';
import { AuthService } from './../../services/auth.service';
import { ModalService } from './../../services/modal.service';

@Component({
  selector: 'app-forgot-modal',
  templateUrl: './forgot-modal.component.html',
  styleUrls: ['./forgot-modal.component.scss']
})
export class ForgotModalComponent {
  
  public form: FormGroup;

  private formSubmited = false;

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly ngbModal: NgbModal,
    private readonly authService: AuthService,
    private readonly modalService: ModalService
  ) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  public submit(): void {
    if (this.form.invalid || this.formSubmited === true) {
      return;
    }

    this.formSubmited = true;

    const formData = {
      email: this.form.get('email').value
    };

    this.authService.frogotPassword(formData).subscribe(res => {
      this.formSubmited = false;
      this.closeModal();
      
      this.ngbModal.open(EmailSentModalComponent, { backdrop: 'static', centered: true, size: 'sm' });
    }, error => {
      this.modalService.showErrorModal(error?.error);
      this.formSubmited = false;
    })
  }

  public closeModal(): void {
    this.activeModal.close();
  }
}
