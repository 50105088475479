import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-successful-modal',
  templateUrl: './successful-modal.component.html',
  styleUrls: ['./successful-modal.component.scss']
})
export class SuccessfulModalComponent implements OnInit, OnDestroy {
  
  @Input() public title = 'Success!';
  @Input() public text = 'Action was successful!';

  private timeoutId = null;

  constructor(
    private readonly activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.timeoutId = setTimeout(() => {
      this.activeModal.close();
    }, 2000);
  }

  ngOnDestroy(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}
