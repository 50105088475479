import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ClipboardModule } from 'ngx-clipboard';
import { FooterBottomComponent } from './components/footer-bottom/footer-bottom.component';
import { FooterGapComponent } from './components/footer-gap/footer-gap.component';
import { FooterTopComponent } from './components/footer-top/footer-top.component';
import { HorizontalSliderModule } from './components/horizontal-slider/horizontal-slider.module';
import { PrivacyPolicyTextComponent } from './components/privacy-policy-text/privacy-policy-text.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsAndConditionsTextComponent } from './components/terms-and-conditions-text/terms-and-conditions-text.component';
import { WorkplanComponent } from './components/workplan/workplan.component';
import { AuthModalComponent } from './modals/auth-modal/auth-modal.component';
import { BackupCodeModalComponent } from './modals/backup-code-modal/backup-code-modal.component';
import { ComingSoonModalComponent } from './modals/coming-soon-modal/coming-soon-modal.component';
import { ConfirmSuccessfulModalComponent } from './modals/confirm-successful-modal/confirm-successful-modal.component';
import { ConfirmTransactionModalComponent } from './modals/confirm-transaction-modal/confirm-transaction-modal.component';
import { DepositModalComponent } from './modals/deposit-modal/deposit-modal.component';
import { EmailSentModalComponent } from './modals/email-sent-modal/email-sent-modal.component';
import { ForgotModalComponent } from './modals/forgot-modal/forgot-modal.component';
import { PrivacyPolicyModalComponent } from './modals/privacy-policy-modal/privacy-policy-modal.component';
import { ProgressInModalComponent } from './modals/progress-in-modal/progress-in-modal.component';
import { CryptolineResultComponent } from './modals/results-modal/components/cryptoline-result/cryptoline-result.component';
import { GoldenlineResultComponent } from './modals/results-modal/components/goldenline-result/goldenline-result.component';
import { ResultsModalComponent } from './modals/results-modal/results-modal.component';
import { SuccessfulModalComponent } from './modals/successful-modal/successful-modal.component';
import { TermsConditionsModalComponent } from './modals/terms-conditions-modal/terms-conditions-modal.component';
import { DepositTransactionDetailsComponent } from './modals/transaction-details-modal/components/deposit-transaction-details/deposit-transaction-details.component';
import { ExchangeTransactionDetailsComponent } from './modals/transaction-details-modal/components/exchange-transaction-details/exchange-transaction-details.component';
import { PayinTransactionDetailsComponent } from './modals/transaction-details-modal/components/payin-transaction-details/payin-transaction-details.component';
import { PayoutTransactionDetailsComponent } from './modals/transaction-details-modal/components/payout-transaction-details/payout-transaction-details.component';
import { WithdrawTransactionDetailsComponent } from './modals/transaction-details-modal/components/withdraw-transaction-details/withdraw-transaction-details.component';
import { TransactionDetailsModalComponent } from './modals/transaction-details-modal/transaction-details-modal.component';
import { TransactionSuccessModalComponent } from './modals/transaction-success-modal/transaction-success-modal.component';
import { DisableTwoFactorAuthModalComponent } from './modals/two-factor-auth/disable-two-factor-auth-modal/disable-two-factor-auth-modal.component';
import { EnableTwoFactorAuthModalComponent } from './modals/two-factor-auth/enable-two-factor-auth-modal/enable-two-factor-auth-modal.component';
import { TwoFactorAuthModalComponent } from './modals/two-factor-auth/two-factor-auth-modal/two-factor-auth-modal.component';
import { TwofaDoneModalComponent } from './modals/twofa-done-modal/twofa-done-modal.component';
import { UnsuccessfulModalComponent } from './modals/unsuccessful-modal/unsuccessful-modal.component';
import { WithdrawModalComponent } from './modals/withdraw-modal/withdraw-modal.component';
import { AuthService } from './services/auth.service';
import { ConectionService } from './services/conection.service';
import { ContactService } from './services/contact.service';
import { ExchangeService } from './services/exchange.service';
import { GameService } from './services/game.service';
import { LimitOrderService } from './services/limit-order.service';
import { MessageService } from './services/message.service';
import { TransactionService } from './services/transaction.service';
import { WithdrawService } from './services/withdraw.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ToggleSliderComponent } from './components/toggle-slider/toggle-slider.component';

@NgModule({
    exports: [
        MatInputModule,
        MatTableModule,
        MatFormFieldModule,
        MatTabsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSliderModule,
        MatBottomSheetModule,
        MatSlideToggleModule
    ],
})
export class MaterialModule {
}

@NgModule({
    declarations: [
        PrivacyComponent,
        FooterTopComponent,
        FooterGapComponent,
        FooterBottomComponent,
        AuthModalComponent,
        ForgotModalComponent,
        EmailSentModalComponent,
        TermsConditionsModalComponent,
        TwoFactorAuthModalComponent,
        EnableTwoFactorAuthModalComponent,
        DisableTwoFactorAuthModalComponent,
        BackupCodeModalComponent,
        TwofaDoneModalComponent,
        ResultsModalComponent,
        DepositModalComponent,
        WithdrawModalComponent,
        TransactionSuccessModalComponent,
        ProgressInModalComponent,
        UnsuccessfulModalComponent,
        SuccessfulModalComponent,
        ComingSoonModalComponent,
        WorkplanComponent,
        ConfirmSuccessfulModalComponent,
        PrivacyPolicyTextComponent,
        PrivacyPolicyModalComponent,
        TermsAndConditionsTextComponent,
        ConfirmTransactionModalComponent,
        TransactionDetailsModalComponent,
        WithdrawTransactionDetailsComponent,
        DepositTransactionDetailsComponent,
        ExchangeTransactionDetailsComponent,
        GoldenlineResultComponent,
        CryptolineResultComponent,
        PayinTransactionDetailsComponent,
        PayoutTransactionDetailsComponent,
        ToggleSliderComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MaterialModule,
        PipesModule,
        QRCodeModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        ClipboardModule,
        HorizontalSliderModule
    ],
    exports: [
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MaterialModule,
        PipesModule,
        QRCodeModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        FooterTopComponent,
        FooterGapComponent,
        FooterBottomComponent,
        PrivacyComponent,
        WorkplanComponent,
        PrivacyPolicyTextComponent,
        PrivacyPolicyModalComponent,
        TermsAndConditionsTextComponent,
        ClipboardModule,
        HorizontalSliderModule,
        ToggleSliderComponent
    ],
    providers: [
        TransactionService,
        GameService,
        AuthService,
        MessageService,
        ContactService,
        WithdrawService,
        ExchangeService,
        ConectionService,
        LimitOrderService,
    ]
})
export class SharedModule {
}
