import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from './../../../core/authentication/credentials.service';

@Component({
  selector: 'app-footer-top',
  templateUrl: './footer-top.component.html',
  styleUrls: ['./footer-top.component.scss']
})
export class FooterTopComponent {

  constructor(
    private readonly router: Router,
    private readonly credentialsService: CredentialsService
  ) { }

  public openContactForm(): void {
    if (this.credentialsService.isAuthenticated()) {
      this.router.navigate(['/message']);
      return;
    }

    this.router.navigate(['/contacts']);
  }
}
