import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ContactSendEmailFormData {
    email: string;
    senderName: string;
    message: string;
    contactFormName: string;
    recaptchaToken: string;
}

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    constructor(
        private readonly httpClient: HttpClient
    ) { }

    public sendMessage(formData: ContactSendEmailFormData): Observable<{ success: boolean }> {
        return this.httpClient.post<{ success: boolean }>('/contact/send', formData);
    }
}