import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "./core/authentication/authentication.service";

export interface IAppConfig {
  production: boolean;
  version: string;
  serverUrl: string;
  defaultLanguage: string;
  credentialsKey: string;
  supportedLanguages: string[];
  websocketUrl: string;
  captchaSiteKey: string;
}

@Injectable()
export class AppConfigService {
  
  static config: IAppConfig;
  private httpClient: HttpClient;

  constructor(
    private readonly handler: HttpBackend
  ) {
    this.httpClient = new HttpClient(this.handler);
  }

  load() {
    const jsonFile = '/assets/config/config.json';

    return new Promise<void>((resolve, reject) => {
      this.httpClient.get(jsonFile).toPromise().then((response: IAppConfig) => {
        AppConfigService.config = <IAppConfig>response;
        resolve();
      }).catch((response: any) => {
        console.error(response);
        reject(`Failed to load the config file`);
      });
    });
  }
}
