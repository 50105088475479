import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    HostListener,
    OnDestroy,
    QueryList,
    ViewChild
} from '@angular/core';
import { debounceTime, map, take, takeUntil, tap } from 'rxjs/operators';
import { fromEvent, Subject } from 'rxjs';

@Component({
    selector: 'app-horizontal-slider',
    templateUrl: './horizontal-slider.component.html',
    styleUrls: ['./horizontal-slider.component.scss']
})
export class HorizontalSliderComponent implements AfterViewInit, OnDestroy {
    @ContentChildren('sliderItem', {read: ElementRef}) sliderItems: QueryList<ElementRef>;
    @ViewChild('sliderContent', {read: ElementRef}) sliderContent: ElementRef;

    items!: ElementRef[]
    elementIndex = 0;
    itemWidth = 0

    private busy = false;
    private componentDestroyed$: Subject<void> = new Subject<void>();

    constructor() {
    }

    @HostListener('window:resize') onResize() {
        this.setItemWidth();
    }

    ngAfterViewInit() {
        this.getSliderItems();
        this.listenScroll();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next();
    }

    next(): void {
        if (!this.busy) {
            const container = this.sliderContent.nativeElement
            container.scrollTo({left: (container.scrollLeft + this.itemWidth), behavior: 'smooth'});
        }
    }

    prev(): void {
        if (!this.busy) {
            const container = this.sliderContent.nativeElement
            container.scrollTo({left: (container.scrollLeft - this.itemWidth), behavior: 'smooth'});
        }
    }

    private setItemWidth() {
        this.itemWidth = this.items[0]?.nativeElement?.clientWidth || 0;
    }

    private listenScroll() {
        fromEvent(this.sliderContent?.nativeElement, 'scroll')
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap(() => this.busy = true),
                map(() => this.sliderContent.nativeElement.scrollLeft),
                debounceTime(200),
            )
            .subscribe(scrollLeft => {
                this.calculateScroll(scrollLeft);
                this.busy = false;
            })
    }

    private calculateScroll(scrollLeft: number) {
        const item = this.items?.[0]?.nativeElement;
        if (item) {
            const container = this.sliderContent.nativeElement;
            const scrollTo = item.clientWidth * Math.round(scrollLeft / item.clientWidth)
            container.scrollTo({left: scrollTo, behavior: 'smooth'});
        }
    }

    private getSliderItems(): void {
        this.items = this.sliderItems?.toArray();
        if (this.items?.length) {
            this.setItemWidth();
        } else {
            this.sliderItems.changes
                .pipe(take(1))
                .subscribe(c => {
                    this.items = c.toArray()
                    this.setItemWidth()
                });
        }
    }

}
