import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface RegisterFormData {
    email: string;
    password: string;
}

export interface ResetPasswordFormData {
    code: string;
    password: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private readonly httpClient: HttpClient
    ) { }

    public register(formData: RegisterFormData): Observable<{ success: boolean }> {
        return this.httpClient.post<{ success: boolean }>('/auth/register', formData);
    }

    public confirmEmail(formData: { code: string }): Observable<{ success: boolean }> {
        return this.httpClient.post<{ success: boolean }>('/auth/confirm/email', formData);
    }

    public frogotPassword(formData: { email: string }): Observable<{ success: boolean }>{
        return this.httpClient.post<{ success: boolean }>('/auth/password/forgot', formData);
    }

    public resetPassword(formData: ResetPasswordFormData): Observable<{ success: boolean }>{
        return this.httpClient.post<{ success: boolean }>('/auth/password/reset', formData);
    }
}
