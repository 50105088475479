import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

    parse(value: string): NgbDateStruct | null {
        if (!value){
            return null;
        }

        const date = value.split('-');
        return {
            day: parseInt(date[0], 10),
            month: parseInt(date[1], 10),
            year: parseInt(date[2], 10)
        };
    }

    format(date: NgbDateStruct | null): string {
        if (!date) {
            return '';
        }

        const formatedDate = `${date.day}-${date.month}-${date.year}`;
        return formatedDate;
    }
}