import { animate, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DepositModalComponent } from 'app/shared/modals/deposit-modal/deposit-modal.component';
import { WithdrawModalComponent } from 'app/shared/modals/withdraw-modal/withdraw-modal.component';
import { UserService } from 'app/shared/services/user.service';
import { AuthenticationService } from './../../../../core/authentication/authentication.service';

@Component({
  selector: 'app-authenticated-header',
  templateUrl: './authenticated-header.component.html',
  styleUrls: ['./authenticated-header.component.scss'],
  animations: [
    trigger('appear', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class AuthenticatedHeaderComponent implements OnInit {
  
  public mobileMenuOpened = false;
  public mobileMenuRoomsOpened = false;

  private toggleButton: any;

  constructor(
    public readonly userService: UserService,
    public readonly location: Location,
    private readonly element: ElementRef,
    private readonly ngbModal: NgbModal,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router
  ) { }

  @ViewChild(NgbDropdown) private readonly ngbDropdown: NgbDropdown;

  async ngOnInit(): Promise<void> {
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
  }

  get fullName(): string {
    const user = this.userService.getUser();

    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }

    return user.email;
  }

  get userAvatar(): string {
    const userAvatar = this.userService.getUserAvatar();
    return userAvatar ? userAvatar : '/assets/imgs/avatar/user-blank.jpg';
  }

  public openGoldenlineRoom(roomId: string): void {
    if (!roomId) {
      return;
    }

    if (this.mobileMenuOpened) {
      this.resetMobileMenu();
    }

    const redirectRoute = `/goldenline/room/${roomId}`;

    if (this.router.url.startsWith('/goldenline/room') === false) {
      this.router.navigate([redirectRoute]);
      this.ngbDropdown.close();

      return;
    }

    if (this.router.url === redirectRoute) {
      this.ngbDropdown.close();
      return;
    }

    this.router.navigate(['/goldenline'], { skipLocationChange: true }).then(() => {
      this.router.navigate([redirectRoute], { replaceUrl: true });
      this.ngbDropdown.close();
    })
  }

  public openCryptolineRoom(roomId: string): void {
    if (!roomId) {
      return;
    }

    if (this.mobileMenuOpened) {
      this.resetMobileMenu();
    }

    const redirectRoute = `/cryptoline/room/${roomId}`;

    if (this.router.url.startsWith('/cryptoline/room') === false) {
      this.router.navigate([redirectRoute]);
      this.ngbDropdown.close();

      return;
    }

    if (this.router.url === redirectRoute) {
      this.ngbDropdown.close();
      return;
    }

    this.router.navigate(['/cryptoline'], { skipLocationChange: true }).then(() => {
      this.router.navigate([redirectRoute], { replaceUrl: true });
      this.ngbDropdown.close();
    })
  }

  public openGoldenlinePlatform(): void {
    if (this.mobileMenuOpened) {
      this.resetMobileMenu();
    }

    this.router.navigate(['/goldenline']);
    this.ngbDropdown.close();
  }

  public openCryptolinePlatform(): void {
    if (this.mobileMenuOpened) {
      this.resetMobileMenu();
    }

    this.router.navigate(['/cryptoline']);
    this.ngbDropdown.close();
  }

  public openDepositModal(): void {
    this.ngbModal.open(DepositModalComponent, { backdrop: 'static', centered: true, size: 'lg', windowClass: 'modal-custom-medium' });
  }

  public openWithdrawModal(): void {
    this.ngbModal.open(WithdrawModalComponent, { backdrop: 'static', centered: true, size: 'lg', windowClass: 'modal-custom-medium' });
  }

  public mobileNavigate(route: string): void {
    if (!route) {
      return;
    }

    this.router.navigate([`/${route}`]);
    this.resetMobileMenu();
  }

  public toggleMobileMenu(): void {
    if (this.mobileMenuOpened === false) {
      this.mobileMenuOpened = true;
      return;
    }

    this.resetMobileMenu();
  }

  public toggleMobileRoomMenu(): void {
    if (this.mobileMenuRoomsOpened === false) {
      this.mobileMenuRoomsOpened = true;
      return;
    }

    this.mobileMenuRoomsOpened = false;
  }

  public logout(): void {
    this.authenticationService.logout().subscribe(res => {
      this.router.navigate(['/home']);
    });
  }

  private resetMobileMenu(): void {
    this.mobileMenuOpened = false;
    this.mobileMenuRoomsOpened = false;
  }
}