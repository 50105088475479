import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LimitOrder, LimitOrderActivity } from './../types/LimitOrder';

export interface LimitOrderFormData {
    activity: LimitOrderActivity;
    amount: number;
    expireDays: number;
    rate: number;
}

@Injectable({
    providedIn: 'root'
})
export class LimitOrderService {

    constructor(
        private readonly httpClient: HttpClient,
    ) { }

    public getAllLimitOrderList(): Observable<Partial<LimitOrder>[]> {
        return this.httpClient.get<Partial<LimitOrder>[]>('/limit-order/public/list');
    }

    public getUserLimitOrders(): Observable<Partial<LimitOrder>[]> {
        return this.httpClient.get<Partial<LimitOrder>[]>('/limit-order/user/orders');
    }

    public getLimitOrdersForUser(): Observable<Partial<LimitOrder>[]> {
        return this.httpClient.get<Partial<LimitOrder>[]>('/limit-order/user/available/orders');
    }

    public createLimitOrder(formData: LimitOrderFormData): Observable<Partial<LimitOrder>> {
        return this.httpClient.post<Partial<LimitOrder>>('/limit-order/create', formData);
    }

    public confirmLimitOrder(formData: { limitOrderId: number }): Observable<{ success: boolean }> {
        return this.httpClient.post<{ success: boolean }>('/limit-order/confirm', formData);
    }

    public takeLimitOrder(formData: { limitOrderId: number }): Observable<{ success: boolean }> {
        return this.httpClient.post<{ success: boolean }>('/limit-order/take', formData);
    }
}
