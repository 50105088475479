import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Logger } from './../../core/logger.service';
import { User } from './../types/User';
import { UserGameResult } from './../types/UserGameResult';

export interface ProfileFormData {
    firstName: string;
    lastName: string;
    phone: string;
    country: string;
    birthDate: string;
}

export interface PasswordFormData {
    password: string;
    newPassword: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private readonly logger = new Logger(UserService.name);

    private user: User = null;

    constructor(
        private readonly httpClient: HttpClient,
    ) { }

    public resolve(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.getUserProfile().subscribe(user => {
                this.user = user;
                resolve();
            }, error => {
                this.logger.error(error);
                resolve();
            })
        });
    }

    public getUser(): User {
        return this.user;
    }

    public getUserAvatar(): string {
        return this.user.avatarUrl;
    }

    public clear(): void {
        this.user = null;
    }

    public updateUser(user: Partial<User>): void {
        for (let key of Object.keys(user)) {
            if (!user[key]) {
                continue;
            }

            this.user[key] = user[key];
        }
    }

    public updateProfile(formData: ProfileFormData): Observable<any> {
        return this.httpClient.put('/user/profile', formData);
    }

    public getUserGameResults(): Observable<UserGameResult[]> {
        return this.httpClient.get<UserGameResult[]>('/user/game/results');
    }

    public uploadProfileAvatar(formData: FormData): Observable<{ avatarUrl: string }>{
        return this.httpClient.put<{ avatarUrl: string }>('/user/avatar', formData);
    }

    public changePassword(formData: PasswordFormData): Observable<{ success: boolean }>{
        return this.httpClient.put<{ success: boolean }>('/user/password', formData);
    }

    public generateUser2fa(): Observable<{ keyUri: string }> {
        return this.httpClient.post<{ keyUri: string }>('/user/2fa/generate', {});
    }

    public enableUser2fa(formData: { authenticatorCode: string }): Observable<{ success: boolean }> {
        return this.httpClient.put<{ success: boolean }>('/user/2fa/enable', formData);
    }

    public disableUser2fa(formData: { authenticatorCode: string }): Observable<{ success: boolean }> {
        return this.httpClient.put<{ success: boolean }>('/user/2fa/disable', formData);
    }

    private getUserProfile(): Observable<User> {
        return this.httpClient.get<User>('/user/me');
    }
}
