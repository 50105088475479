import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticatedLayoutService } from './layouts/authenticated-layout/authenticated-layout.service';
import { MainLayoutService } from './layouts/main-layout/main-layout.service';

const routes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    AuthenticatedLayoutService.childRoutes([
        { path: 'dashboard', loadChildren: 'app/pages/dashboard/dashboard.module#DashboardModule' },
        { path: 'goldenline', loadChildren: 'app/pages/games/goldenline/goldenline.module#GoldenlineModule' },
        { path: 'cryptoline', loadChildren: 'app/pages/games/cryptoline/cryptoline.module#CryptolineModule' },
        { path: 'profile-settings', loadChildren: 'app/pages/profile-settings/profile-settings.module#ProfileSettingsModule' },
        { path: 'results', loadChildren: 'app/pages/results/results.module#ResultsModule' },
        { path: 'statement', loadChildren: 'app/pages/statement/statement.module#StatementModule' },
        { path: 'message', loadChildren: 'app/pages/message/message.module#MessageModule' },
        { path: 'privacy', loadChildren: 'app/pages/privacy-policy/privacy-policy.module#PrivacyPolicyModule'},
        { path: 'cookies', loadChildren: 'app/pages/cookie-policy/cookie-policy.module#CookiePolicyModule'},
        { path: 'terms', loadChildren: 'app/pages/temrs-conditions/terms-conditions.module#TermsConditionsModule'},
        { path: 'partner', loadChildren: 'app/pages/home/partnership/partnership.module#PartnershipModule'},
        { path: 'exchange', loadChildren: 'app/pages/exchange/exchange.module#ExchangeModule'},
    ]),
    MainLayoutService.childRoutes([
        { path: 'home', loadChildren: 'app/pages/home/home.module#HomeModule'},
        { path: 'terms-conditions', loadChildren: 'app/pages/temrs-conditions/terms-conditions.module#TermsConditionsModule'},
        { path: 'privacy-policy', loadChildren: 'app/pages/privacy-policy/privacy-policy.module#PrivacyPolicyModule'},
        { path: 'cookie-policy', loadChildren: 'app/pages/cookie-policy/cookie-policy.module#CookiePolicyModule'},
        { path: 'contacts', loadChildren: 'app/pages/contacts/contacts.module#ContactsModule'},
        { path: 'confirm/email/:code', loadChildren: 'app/pages/confirm-email/confirm.email.module#ConfirmEmailModule'},
        { path: 'partnership', loadChildren: 'app/pages/home/partnership/partnership.module#PartnershipModule'},
        { path: 'coin', loadChildren: 'app/pages/votery-coin/votery-coin.module#VoteryCoinModule'},
        { path: 'reset/password/:code', loadChildren: 'app/pages/reset-password/reset-password.module#ResetPasswordModule'},
        { path: 'roadmap', loadChildren: 'app/pages/roadmap/roadmap.module#RoadmapModule'},
        { path: 'exchange-info', loadChildren: 'app/pages/exchange-info/exchange-info.module#ExchangeInfoModule'},
        // { path: 'news', loadChildren: 'app/pages/news/news.module#NewsModule'},
        // { path: 'news-article', loadChildren: 'app/pages/news-article/news-article.module#NewsArticleModule'},
        // { path: 'developers', loadChildren: 'app/pages/home/developers/developers.module#DevelopersModule'},
    ]),
    { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
