import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-email-sent-modal',
  templateUrl: './email-sent-modal.component.html',
  styleUrls: ['./email-sent-modal.component.scss']
})
export class EmailSentModalComponent {

  constructor(
    private readonly activeModal: NgbActiveModal
  ) { }

  public closeModal(): void  {
    this.activeModal.close();
  }
}
