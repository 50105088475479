import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from 'app/shared/services/user.service';
import { WsService } from 'app/shared/services/ws.service';
import { Observable, Observer, of } from 'rxjs';
import { AccountService } from './../../shared/services/account.service';
import { ConectionService } from './../../shared/services/conection.service';
import { GameService } from './../../shared/services/game.service';
import { Credentials, CredentialsService } from './credentials.service';

export interface LoginContext {
  email: string;
  password: string;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  private isUserAuthorized: boolean = false;

  constructor(
    private readonly credentialsService: CredentialsService,
    private readonly httpClient: HttpClient,
    private readonly wsService: WsService,
    private readonly gameService: GameService,
    private readonly accountService: AccountService,
    private readonly userService: UserService,
    private readonly conectionService: ConectionService,
  ) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  public login(context: LoginContext): Observable<Credentials> {
    this.clearCredentials();

    return new Observable((observer: Observer<any>) => {
      const formData = {
        email: context.email,
        password: context.password
      };

      return this.httpClient.post('/auth/login', formData)
        .subscribe((response: { token: string }) => {
          const data = {
            email: context.email,
            token: response.token
          };

          this.credentialsService.setCredentials(data);

          observer.next(data);
          observer.complete();
        }, error => {
          if (error instanceof HttpErrorResponse && error.status == 417) {
            observer.error(error);
          } else {
            observer.error({ error: 'Server error' });
          }
        });
    });
  }

  public async isAuthorized(): Promise<boolean> {
    if (!this.credentialsService.isAuthenticated()) {
      return false;
    }

    if (this.isUserAuthorized) {
      return true;
    }

    try {
      const response = await this.httpClient.get<{ loggedIn: boolean, clientId: string }>('/auth/status').toPromise();
      const loggedIn = response && response.loggedIn === true;

      if (!loggedIn) {
        this.logout();
        return false;
      }
      
      this.credentialsService.setClientId(response.clientId);
      this.wsService.connect({token: this.credentialsService.credentials.token, reconnect: false}).subscribe(res => {
          this.wsService.emitMessage(res);
      }, err => {
        console.log(err);
      });

      this.isUserAuthorized = true;
      return true;
    } catch (err) {
      return false;
    }
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  public logout(): Observable<boolean> {
    this.clearCredentials();
    this.userService.clear();
    this.gameService.disconnect();
    this.accountService.disconnect();
    this.conectionService.clear();
    this.wsService.close();
    
    return of(true);
  }

  private clearCredentials(): void {
    this.credentialsService.setCredentials();
    this.isUserAuthorized = false;
  }
}
