import { Injectable } from '@angular/core';
import { GoldenlineService } from 'app/pages/games/goldenline/goldenline.service';
import { CryptolineService } from './../../pages/games/cryptoline/cryptoline.service';
import { PlayerRoom } from './../types/PlayerRoom';

@Injectable({
    providedIn: 'root'
})
export class GameService {

    private readonly games: { [code: string]: { getPlayerRooms: () => PlayerRoom[] } } = {
        'GOLDENLINE': { getPlayerRooms: this.getGoldenlinePlayerRooms.bind(this) },
        'CRYPTOLINE': { getPlayerRooms: this.getCryptolinePlayerRooms.bind(this) },
    }

    constructor(
        private readonly goldenlineService: GoldenlineService,
        private readonly cryptolineService: CryptolineService,
    ) { }

    async resolve() {
        this.connect();
    }

    public connect(): void {
        this.goldenlineService.connect();
        this.cryptolineService.connect();
    }

    public disconnect(): void {
        this.goldenlineService.disconnect();
        this.cryptolineService.disconnect();
    }

    public getPlayerRooms(code: string): PlayerRoom[] {
        return this.games[code].getPlayerRooms();
    }

    private getGoldenlinePlayerRooms(): PlayerRoom[] {
        return this.goldenlineService.getPlayerRooms();
    }

    private getCryptolinePlayerRooms(): PlayerRoom[] {
        return this.cryptolineService.getPlayerRooms();
    }
}