import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-coming-soon-modal',
  templateUrl: './coming-soon-modal.component.html',
  styleUrls: ['./coming-soon-modal.component.scss']
})
export class ComingSoonModalComponent {
  @Input() public text = 'We will let you know when it is launched!';

  constructor(
    private readonly activeModal: NgbActiveModal,
  ) { }

  public closeModal(): void {
    this.activeModal.close();
  }
}
