import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbActiveModal, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GoldenlineService } from 'app/pages/games/goldenline/goldenline.service';
import { UserService } from 'app/shared/services/user.service';
import { WsService } from 'app/shared/services/ws.service';
import { AppConfigService } from './app-config.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { CoreModule } from './core/core.module';
import { CustomDateParserFormatter } from './CustomDateParserFormatter';
import { AuthenticatedModule } from './layouts/authenticated-layout/authenticated-layout.module';
import { MainLayoutModule } from './layouts/main-layout/main-layout.module';
import { CryptolineService } from './pages/games/cryptoline/cryptoline.service';
import { AccountService } from './shared/services/account.service';
import { SharedModule } from './shared/shared.module';

export function initializeApp(appConfigService: AppConfigService) {
    return (): Promise<any> => {
        return appConfigService.load();
    }
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HttpClientModule,
        CommonModule,
        BrowserAnimationsModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        AppRoutingModule,
        SharedModule,
        MainLayoutModule,
        AuthenticatedModule,
        CoreModule,
    ],
    providers: [
        NgbActiveModal,
        AppConfigService,
        WsService,
        AccountService,
        UserService,
        GoldenlineService,
        CryptolineService,
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfigService], multi: true },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
