import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExchangePair } from './../types/ExchangePair';
import { Transaction } from './../types/Transaction';

export interface ExchangeFormData {
    exchangePairId: number;
    amount: number;
}

@Injectable({
    providedIn: 'root'
})
export class ExchangeService {

    constructor(
        private readonly httpClient: HttpClient,
    ) { }

    public getExchangePairs(): Observable<ExchangePair[]> {
        return this.httpClient.get<ExchangePair[]>('/exchange/pairs');
    }

    public getVoteryCoinValues(): Observable<{ amount: number, value: number }> {
        return this.httpClient.get<{ amount: number, value: number }>('/exchange/votery-coin');
    }

    public createExchangeTransaction(formData: ExchangeFormData): Observable<Partial<Transaction>> {
        return this.httpClient.post<Partial<Transaction>>('/exchange/create', formData);
    }

    public confirmExchangeTransaction(formData: { transactionId: number }): Observable<{ success: boolean }> {
        return this.httpClient.post<{ success: boolean }>('/exchange/confirm', formData);
    }
}
