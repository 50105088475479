import { EventEmitter, Injectable } from '@angular/core';
import { CredentialsService } from 'app/core/authentication/credentials.service';
import { WsService } from 'app/shared/services/ws.service';
import { EventEmitter as Listener } from 'events';
import { CryptolineEventMessage, MessageType } from './types/events/CryptolineEventMessage';
import { CryptolineJoinGameEventData } from './types/events/CryptolineJoinGameEventData';
import { CryptolineNewRoomEventData } from './types/events/CryptolineNewRoomEventData';
import { CryptolineNewRoundEventData } from './types/events/CryptolineNewRoundEventData';
import { CryptolinePlayerNewRoundEventData } from './types/events/CryptolinePlayerNewRoundEventData';
import { CryptolinePlayerRoomEndEventData } from './types/events/CryptolinePlayerRoomEndEventData';
import { CryptolinePrizeFundEventData } from './types/events/CryptolinePrizeFundEventData';
import { CryptolineRoomEndEventData } from './types/events/CryptolineRoomEndEventData';
import { CryptolineRoomInfoEventData } from './types/events/CryptolineRoomInfoEventData';
import { CryptolineRoomSelectedEventData } from './types/events/CryptolineRoomSelectedEventData';
import { CryptolineRoomsEventData } from './types/events/CryptolineRoomsEventData';
import { CryptolineSequenceEventData } from './types/events/CryptolineSequenceEventData';
import { CryptolinePlayerRoom } from './types/CryptolinePlayerRoom';
import { CryptolineRoom } from './types/CryptolineRoom';

@Injectable({
    providedIn: 'root',
})
export class CryptolineService {

    public readonly cryptolineGameEvent: EventEmitter<CryptolineEventMessage> = new EventEmitter<CryptolineEventMessage>();

    private rooms: CryptolineRoom[] = [];
    private playerRooms: CryptolinePlayerRoom[] = [];
    private messageListener: Listener = null;
    private timerEnabled = false;
    private timerInterval = null;

    private readonly eventHandler: { [event: string]: { emit: (data: any) => void } } = {
        'cryptoline.new-game': { emit: this.onNewRoomEvent.bind(this) },
        'cryptoline.games': { emit: this.onRoomsEvent.bind(this) },
        'cryptoline.room': { emit: this.onRoomInfoEvent.bind(this) },
        'cryptoline.sequence': { emit: this.onSequenceEvent.bind(this) },
        'cryptoline.room-selected': { emit: this.onRoomSelectedEvent.bind(this) },
        'cryptoline.new-round': { emit: this.onNewRoundEvent.bind(this) },
        'cryptoline.player-new-round': { emit: this.onPlayerNewRoundEvent.bind(this) },
        'cryptoline.prize-fund': { emit: this.onPrizeFundEvent.bind(this) },
        'cryptoline.joingame': { emit: this.onJoinGameEvent.bind(this) },
        'cryptoline.game-end': { emit: this.onRoomEndEvent.bind(this) },
        'cryptoline.player-game-end': { emit: this.onPlayerRoomEndEvent.bind(this) },
    };

    constructor(
        private readonly credentialsService: CredentialsService,
        private readonly wsService: WsService
    ) {
        this.clearState();
    }

    public connect(): void {
        this.wsService.sendMessage({ event: 'cryptoline.getrooms', data: { playerId: this.credentialsService.getClientId() } });

        this.messageListener = this.wsService.getMessages().addListener('cryptoline', (message: { data: any, event: string }) => {
            if (!message || !message.event) {
                return;
            }

            const event = this.eventHandler[message.event];
            if (!event) {
                return;
            }

            event.emit(message.data);
        });
    }

    public disconnect(): void {
        if (this.messageListener) {
            this.messageListener.removeAllListeners();
        }

        this.clearState();
    }

    public clearState(): void {
        this.rooms = [];
        this.playerRooms = [];
        this.timerEnabled = false;
        this.clearRoomTimerInterval();
    }

    public getRooms(): CryptolineRoom[] {
        return this.rooms;
    }

    public getPlayerRooms(): CryptolinePlayerRoom[] {
        return this.playerRooms;
    }

    public getRoomById(gameId: string): null | CryptolineRoom {
        if (this.rooms.length === 0) {
            return null;
        }

        return this.rooms.find(r => r.gameId === gameId);
    }

    public selectRoom(roomGameId: string): void {
        this.wsService.sendMessage({ event: 'cryptoline.selectroom', data: { playerId: this.credentialsService.getClientId(), gameId: roomGameId } });
    }

    public joinGame(roomGameId: string, sequence: string) {
        this.wsService.sendMessage({ event: 'cryptoline.joingame', data: { playerId: this.credentialsService.getClientId(), gameId: roomGameId, sequence: sequence } });
    }

    public submitVote(roomGameId: string, vote: { [votedNumber: number]: number }) {
        this.wsService.sendMessage({ event: 'cryptoline.vote', data: { playerId: this.credentialsService.getClientId(), gameId: roomGameId, voteSequence: vote } });
    }

    public generateSequence(roomGameId: string) {
        this.wsService.sendMessage({ event: 'cryptoline.generates', data: { playerId: this.credentialsService.getClientId(), gameId: roomGameId } });
    }

    public getRoomInfo(roomGameId: string) {
        this.wsService.sendMessage({ event: 'cryptoline.roomInfo', data: { gameId: roomGameId } });
    }

    public changeCurrentActivePlayerRoom(roomId: string): void {
        this.removeCurrentActivePlayerRoom();

        console.log(roomId);

        const newActiveRoom = this.playerRooms.find(r => r.gameId === roomId);
        if (!newActiveRoom) {
            return;
        }

        newActiveRoom.currentActive = true;
    }

    public removeCurrentActivePlayerRoom(): void {
        const currentActive = this.playerRooms.find(r => r.currentActive === true);
        if (!currentActive) {
            return;
        }

        currentActive.currentActive = false;
    }

    private onNewRoomEvent(data: CryptolineNewRoomEventData): void {
        this.addRooms(data);
    }

    private onRoomsEvent(data: CryptolineRoomsEventData): void {
        this.addRooms(data.games);
        this.addPlayerRooms(data.playerGames);
    }

    private onRoomInfoEvent(data: CryptolineRoomInfoEventData): void {
        this.cryptolineGameEvent.emit({ type: MessageType.ROOM_INFO, message: data, gameId: data.gameId });
    }

    private onSequenceEvent(data: CryptolineSequenceEventData): void {
        this.cryptolineGameEvent.emit({ type: MessageType.SEQUENCE, message: data, gameId: data.gameId });
    }

    private onRoomSelectedEvent(data: CryptolineRoomSelectedEventData): void {
        this.cryptolineGameEvent.emit({ type: MessageType.ROOM_SELECTED, message: data, gameId: data.gameState.gameId });
    }

    private onJoinGameEvent(data: CryptolineJoinGameEventData): void {
        this.addPlayerRoom(data);
        this.cryptolineGameEvent.emit({ type: MessageType.JOIN_GAME, message: data, gameId: data.gameId });
    }

    private onPrizeFundEvent(data: CryptolinePrizeFundEventData): void {
        this.cryptolineGameEvent.emit({ type: MessageType.PRIZE_FUND, message: data, gameId: data.gameId });
        this.updateRoomPrizeFund(data);
    }

    private onNewRoundEvent(data: CryptolineNewRoundEventData): void {
        this.cryptolineGameEvent.emit({ type: MessageType.NEW_ROUND, message: data, gameId: data.gameId });
    }

    private onPlayerNewRoundEvent(data: CryptolinePlayerNewRoundEventData): void {
        this.cryptolineGameEvent.emit({ type: MessageType.PLAYER_NEW_ROUND, message: data, gameId: data.playerState.gameId });
    }

    private onRoomEndEvent(data: CryptolineRoomEndEventData): void {
        this.cryptolineGameEvent.emit({ type: MessageType.ROOM_END, message: data, gameId: data.gameId });
        this.removeRoom(data);
        this.removePlayerRoom(data);
    }

    private onPlayerRoomEndEvent(data: CryptolinePlayerRoomEndEventData): void {
        this.cryptolineGameEvent.emit({ type: MessageType.PLAYER_ROOM_END, message: data, gameId: data.gameId });
    }

    private addRooms(rooms: CryptolineRoom[]): void {
        if (!Array.isArray(rooms)) {
            return;
        }

        for (let room of rooms) {
            const existingRoom = this.rooms.find(r => r.gameId === room.gameId);
            if (existingRoom) {
                existingRoom.remainingSeconds = room.remainingSeconds;
                existingRoom.remainingRoundSeconds = room.remainingRoundSeconds;

                continue;
            }

            this.rooms.push({
                currency: room.currency,
                entryFee: room.entryFee,
                prizeFund: room.prizeFund,
                totalDurationSeconds: room.totalDurationSeconds,
                remainingSeconds: room.remainingSeconds,
                remainingRoundSeconds: room.remainingRoundSeconds,
                gameId: room.gameId,
                label: room.label
            });
        }

        if (this.timerEnabled === false && this.rooms.length > 0) {
            this.startRoomTimer();
        }
    }

    private addPlayerRooms(rooms: CryptolinePlayerRoom[]): void {
        if (!Array.isArray(rooms)) {
            return;
        }

        for (let room of rooms) {
            const existingRoom = this.playerRooms.find(r => r.gameId === room.gameId);
            if (existingRoom) {
                continue;
            }

            this.addPlayerRoom(room);
        }
    }

    private addPlayerRoom(room: CryptolinePlayerRoom | CryptolineJoinGameEventData): void {
        this.playerRooms.push({
            gameId: room.gameId,
            currency: room.currency,
            entryFee: room.entryFee
        });
    }

    private updateRoomPrizeFund(room: { gameId: string, prizeFund: number }): void {
        const existingRoom = this.rooms.find(r => r.gameId === room.gameId);
        if (!existingRoom) {
            return;
        }

        existingRoom.prizeFund = room.prizeFund;
    }

    private removeRoom(room: CryptolineRoomEndEventData): void {
        const index = this.rooms.findIndex(r => r.gameId === room.gameId);
        if (index < 0) {
            return;
        }

        this.rooms.splice(index, 1);
    }

    private removePlayerRoom(room: CryptolineRoomEndEventData): void {
        if (this.playerRooms.length === 0) {
            return;
        }

        const index = this.playerRooms.findIndex(r => r.gameId === room.gameId);
        if (index < 0) {
            return;
        }

        this.playerRooms.splice(index, 1);
    }

    private startRoomTimer(): void {
        this.timerEnabled = true;

        this.timerInterval = setInterval(() => {
            if (this.rooms.length === 0) {
                this.timerEnabled = false;
                this.clearRoomTimerInterval();
            }

            for (const room of this.rooms) {
                room.remainingSeconds -= 1;
            }
        }, 1000);
    }

    private clearRoomTimerInterval(): void {
        clearInterval(this.timerInterval);
    }
}
