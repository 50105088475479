import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Transaction } from './../types/Transaction';

@Injectable({
    providedIn: 'root'
})
export class TransactionService {

    constructor(
        private readonly httpClient: HttpClient,
    ) { }

    public getUserTransactions(): Observable<Partial<Transaction>[]> {
        return this.httpClient.get<Partial<Transaction>[]>('/transaction/list');
    }
}
