import { ConfirmSuccessfulModalComponent } from './../modals/confirm-successful-modal/confirm-successful-modal.component';
import { HttpError } from './../types/HttpError';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessfulModalComponent } from './../modals/successful-modal/successful-modal.component';
import { UnsuccessfulModalComponent } from './../modals/unsuccessful-modal/unsuccessful-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private readonly ngbModal: NgbModal
  ) { }

  public showSuccessModal(text: string): void {
    const modal = this.ngbModal.open(SuccessfulModalComponent, { centered: true });
    modal.componentInstance.text = text;
  }

  public showUnsuccessModal({ title = null, text = null }: { title?: string, text?: string } = {}): void {
    const modal = this.ngbModal.open(UnsuccessfulModalComponent, { centered: true });
    modal.componentInstance.title = title ? title: 'Sorry!';
    modal.componentInstance.text = text ? text : 'Unfortunately, service is currently unavailable, please try again later!';
  }

  public showErrorModal(error: HttpError, title: string = 'Sorry!'): void {
    let text = 'Unfortunately, service is currently unavailable, please try again later!';
    if (!error) {
      return;
    }

    if (error.statusCode !== 500 && error.statusCode !== 400 && error.error.message) {
      text = error.error.message;
    }

    const modal = this.ngbModal.open(UnsuccessfulModalComponent, { centered: true });
    modal.componentInstance.title = title;
    modal.componentInstance.text = text;
  }

  public showConfirmSuccessModal(text: string): void {
    const modal = this.ngbModal.open(ConfirmSuccessfulModalComponent, { centered: true });
    modal.componentInstance.text = text;
  }
}