import { Transaction } from './../types/Transaction';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface WithdrawFormData {
    accountId: number;
    address: string;
    amount: number;
    note: string;
}

@Injectable({
    providedIn: 'root'
})
export class WithdrawService {

    constructor(
        private readonly httpClient: HttpClient,
    ) { }

    public createWithdrawTransaction(formData: WithdrawFormData): Observable<Partial<Transaction>> {
        return this.httpClient.post<Partial<Transaction>>('/withdraw/create', formData);
    }

    public confirmWithdrawTransaction(formData: { transactionId: number }): Observable<{ success: boolean }> {
        return this.httpClient.post<{ success: boolean }>('/withdraw/confirm', formData);
    }
}
