import { Component, Input } from '@angular/core';
import { TransactionStatusColors } from './../../../../constant/TransactionStatusColors';
import { Transaction } from './../../../../types/Transaction';

@Component({
  selector: 'app-deposit-transaction-details-component',
  templateUrl: './deposit-transaction-details.component.html',
  styleUrls: ['./../../transaction-details-modal.component.scss']
})
export class DepositTransactionDetailsComponent {
  @Input() public transaction: Partial<Transaction>;
  public readonly statusColors = TransactionStatusColors;
}
