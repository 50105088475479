import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-two-factor-auth-modal',
  templateUrl: './two-factor-auth-modal.component.html',
  styleUrls: ['./two-factor-auth-modal.component.scss']
})
export class TwoFactorAuthModalComponent {

  public readonly form = new FormGroup({
    code: new FormControl('', [Validators.required, Validators.maxLength(6)])
  });

  constructor(
    private readonly activeModal: NgbActiveModal
  ) { }

  public submitForm(): void {
    if (this.form.invalid) {
      return;
    }

    this.activeModal.close({ action: true, code: this.form.get('code').value });
  }

  public closeModal(): void {
    this.activeModal.close(false);
  }
}
