import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'app/shared/services/user.service';
import { ModalService } from './../../../services/modal.service';

@Component({
  selector: 'app-disable-two-factor-auth-modal',
  templateUrl: './disable-two-factor-auth-modal.component.html',
  styleUrls: ['./disable-two-factor-auth-modal.component.scss']
})
export class DisableTwoFactorAuthModalComponent {

  public readonly form = new FormGroup({
    code: new FormControl('', [Validators.required, Validators.maxLength(6)])
  });

  private formSubmited = false;

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly userService: UserService,
    private readonly modalService: ModalService
  ) { }

  public submitForm(): void {
    if (this.form.invalid || this.formSubmited === true) {
      return;
    }

    this.formSubmited = true;

    const formData = {
      authenticatorCode: this.form.get('code').value
    }

    this.userService.disableUser2fa(formData).subscribe(res => {
      this.activeModal.close(true);
    }, error => {
      this.modalService.showErrorModal(error?.error);
      this.formSubmited = false;
    })
  }

  public closeModal(): void {
    this.activeModal.close();
  }
}
