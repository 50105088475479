import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Transaction } from './../../types/Transaction';

@Component({
  selector: 'app-transaction-details-modal',
  templateUrl: './transaction-details-modal.component.html',
  styleUrls: ['./transaction-details-modal.component.scss']
})
export class TransactionDetailsModalComponent {
  @Input() public transaction: Partial<Transaction>;

  constructor(
    private readonly activeModal: NgbActiveModal,
  ) { }

  public closeModal(): void {
    this.activeModal.close();
  }
}
