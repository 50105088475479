import { Route, Routes } from '@angular/router';
import { UserService } from 'app/shared/services/user.service';
import { AuthenticationGuard } from './../../core/authentication/authentication.guard';
import { AccountService } from './../../shared/services/account.service';
import { ConectionService } from './../../shared/services/conection.service';
import { GameService } from './../../shared/services/game.service';
import { AuthenticatedLayoutComponent } from './authenticated-layout.component';

/**
 * Provides helper methods to create routes.
 */
export class AuthenticatedLayoutService {

  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: AuthenticatedLayoutComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
      resolve: [
        AccountService,
        GameService,
        UserService,
        ConectionService,
      ]
    };
  }
}
