import { Injectable } from "@angular/core";
import { AppConfigService } from "app/app-config.service";
import { EventEmitter } from "events";
import { EMPTY, Observable, Subject, timer } from "rxjs";
import { catchError, delayWhen, retryWhen, switchAll, tap } from "rxjs/operators";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { CredentialsService } from './../../core/authentication/credentials.service';

@Injectable({
    providedIn: "root",
})
export class WsService {
    private socket$: WebSocketSubject<any>;
    private messagesSubject$ = new Subject();
    private messageEmitter = new EventEmitter();
    public messages$ = this.messagesSubject$.pipe(
        switchAll(),
        catchError((e) => {
            console.error(e);
            throw e;
        })
    );

    constructor(
        private readonly credentialsService: CredentialsService
    ) { }

    public connect(cfg: { token: string, reconnect: boolean } = { token: null, reconnect: false }): WebSocketSubject<any> {
        if (!this.socket$ || this.socket$.closed) {
            this.socket$ = this.getNewWebSocket(cfg.token);
            const messages = this.socket$.pipe(
                cfg.reconnect ? this.reconnect : (o) => o,
                tap({
                    error: (error) => console.log('error', error),
                }),
                catchError((_) => EMPTY)
            );
            this.messagesSubject$.next(messages);
        }

        return this.socket$;
    }

    public emitMessage(message: { data: any, event: string }): void {
        const event = message.event.substring(0, message.event.indexOf('.'));
        this.messageEmitter.emit(event, message);
    }

    public getMessages(): EventEmitter {
        return this.messageEmitter;
    }

    public sendMessage(message: { event: string, data: any }): void {
        this.socket$.next(message);
    }

    public close(): void {
        this.socket$.complete();
        this.socket$ = undefined;
    }

    private getNewWebSocket(token: string) {
        console.log('get webscoket');
        console.log(AppConfigService.config.websocketUrl);
        return webSocket({
            url: AppConfigService.config.websocketUrl + `?token=${token}&time=${new Date().getTime()}`,
            openObserver: {
                next: () => {
                    console.log("[WsService]: connection opened");
                },
            },
            closeObserver: {
                next: (closeEvent) => {
                    console.log("[WsService]: connection closed", closeEvent);
                    this.refreshSocket(token);
                },
            },
        });
    }

    private refreshSocket(token: string): void {
        if (!this.credentialsService.isAuthenticated()) {
            return;
        }

        this.socket$ = undefined;
        this.connect({ token, reconnect: true });
    }

    private reconnect(observable: Observable<any>): Observable<any> {
        console.log("[WsService] Try to reconnect")

        return observable.pipe(
            retryWhen((errors) =>
                errors.pipe(
                    tap((val) => console.log("[WsService] Try to reconnect", val)),
                    delayWhen((_) => timer(5000))
                )
            )
        );
    }
}