import { MainLayoutHeaderComponent } from './main-layout-header/main-layout-header.component';
import { MainLayoutFooterMenuComponent } from './main-layout-footer-menu/main-layout-footer-menu.component';
import { NgModule } from '@angular/core';
import { SharedModule } from './../../shared/shared.module';
import { MainLayoutComponent } from './main-layout.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    MainLayoutComponent,
    MainLayoutFooterMenuComponent,
    MainLayoutHeaderComponent
  ]
})
export class MainLayoutModule {
}
