import { Component, Input, OnInit } from '@angular/core';
import { UserGameResult } from './../../../../types/UserGameResult';

interface PlayerSequence {
  sequence: {
    number: string;
    isMatched: boolean;
  }[];
  currentUser?: boolean;
}

@Component({
  selector: 'app-cryptoline-result-component',
  templateUrl: './cryptoline-result.component.html',
  styleUrls: ['./cryptoline-result.component.scss']
})
export class CryptolineResultComponent implements OnInit {
  @Input() public result: UserGameResult;

  public playerSequences: PlayerSequence[] = [];

  ngOnInit(): void {
    this.setPlayerSequences();
  }

  private setPlayerSequences(): void {
    for (const playerSequence of this.result.allPlayerSequences) {
      const matchedPlayerSequence = this.getMatchedPlayerSequence(playerSequence);
      this.playerSequences.push(matchedPlayerSequence);
    }
  }

  private getMatchedPlayerSequence(playerSequence: string): PlayerSequence {
    const matchedPlayerSequence: PlayerSequence = {
      sequence: [],
    };

    if (playerSequence === this.result.playerSequence) {
      matchedPlayerSequence.currentUser = true;
    }

    for (let index = 0; index < playerSequence.length; index++) {
      const sequenceNumber = playerSequence[index];
      const roomSequenceNumber = this.result.sequenceNumbers[index].number;

      const isMatched = Number(sequenceNumber) === roomSequenceNumber ? true : false;
      matchedPlayerSequence.sequence.push({ number: sequenceNumber, isMatched });
    }

    return matchedPlayerSequence
  }
}
