import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ForgotModalComponent } from '../forgot-modal/forgot-modal.component';
import { TermsConditionsModalComponent } from '../terms-conditions-modal/terms-conditions-modal.component';
import { AuthenticationService } from './../../../core/authentication/authentication.service';
import { Logger } from './../../../core/logger.service';
import { AuthService, RegisterFormData } from './../../services/auth.service';
import { ModalService } from './../../services/modal.service';
import { PrivacyPolicyModalComponent } from './../privacy-policy-modal/privacy-policy-modal.component';

@Component({
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss']
})
export class AuthModalComponent {
  private readonly logger = new Logger(AuthModalComponent.name);

  public loginPasswordInput = 'password';
  public registerPasswordInput = 'password';
  public loginErrorMessage: string = null;

  public readonly loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)])
  });
  public readonly registerForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    agree: new FormControl(false, [Validators.required, Validators.requiredTrue])
  });

  private loginFormSubmited = false;
  private registerFormSubmited = false;

  constructor(
    public readonly activeModal: NgbActiveModal,
    private readonly router: Router,
    private readonly ngbModal: NgbModal,
    private readonly authService: AuthService,
    private readonly authenticationService: AuthenticationService,
    private readonly route: ActivatedRoute,
    private readonly modalService: ModalService,
  ) { }
  
  public showLoginPassword(): void {
    if (this.loginPasswordInput === 'password') {
      this.loginPasswordInput = 'text';
      return;
    }

    this.loginPasswordInput = 'password';
  }

  public showRegistrationPassword(): void {
    if (this.registerPasswordInput === 'password') {
      this.registerPasswordInput = 'text';
      return;
    }

    this.registerPasswordInput = 'password';
  }

  public openTermsAnConditionsdModal(event: Event): void {
    event.preventDefault();
    this.ngbModal.open(TermsConditionsModalComponent, { backdrop: 'static', centered: true, size: 'lg' });
  }

  public openPricacyPolicyModal(event: Event): void {
    event.preventDefault();
    this.ngbModal.open(PrivacyPolicyModalComponent, { backdrop: 'static', centered: true, size: 'lg' });
  }

  public login(): void {
    if (this.loginForm.invalid || this.loginFormSubmited) {
      return;
    }

    this.loginFormSubmited = true;

    const formData = {
      email: this.loginForm.get('email').value,
      password: this.loginForm.get('password').value
    }

    this.authenticationService.login(formData).subscribe(credentials => {
      this.logger.debug(`${credentials.email} successfully logged in`);

      this.activeModal.close();
      this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
    }, error => {
      this.logger.error(error);
      
      this.loginFormSubmited = false;
      this.loginErrorMessage = 'Email or password is incorrect!';
    });
  }

  public frogotPassword(): void {
    this.activeModal.close();
    this.ngbModal.open(ForgotModalComponent, { backdrop: 'static', centered: true, size: 'sm' });
  }

  async register() {
    if (this.registerForm.invalid || this.registerFormSubmited) {
      return;
    }

    this.registerFormSubmited = true;

    const formData: RegisterFormData = {
      email: this.registerForm.get('email').value,
      password: this.registerForm.get('password').value
    }

    this.authService.register(formData).subscribe(res => {
      this.modalService.showConfirmSuccessModal('We have sent an email with a confirmation link to your email address. To complete the registration process, please click the confirmation link.');
      this.registerFormSubmited = false;
    }, error => {
      this.modalService.showErrorModal(error?.error);
      this.registerFormSubmited = false;
    })
  }
}
